@font-face {
font-family: '__TWKLausanne400_bfa03e';
src: url(/_next/static/media/0e797165e26b272a-s.p.woff2) format('woff2');
font-display: swap;
font-style: normal;
}@font-face {font-family: '__TWKLausanne400_Fallback_bfa03e';src: local("Arial");ascent-override: 89.26%;descent-override: 19.61%;line-gap-override: 0.00%;size-adjust: 102.08%
}.__className_bfa03e {font-family: '__TWKLausanne400_bfa03e', '__TWKLausanne400_Fallback_bfa03e';font-style: normal
}

@font-face {
font-family: '__TWKLausanne400Italic_3d791e';
src: url(/_next/static/media/0e797165e26b272a-s.p.woff2) format('woff2');
font-display: swap;
font-style: italic;
}@font-face {font-family: '__TWKLausanne400Italic_Fallback_3d791e';src: local("Arial");ascent-override: 89.26%;descent-override: 19.61%;line-gap-override: 0.00%;size-adjust: 102.08%
}.__className_3d791e {font-family: '__TWKLausanne400Italic_3d791e', '__TWKLausanne400Italic_Fallback_3d791e';font-style: italic
}

@font-face {
font-family: '__TWKLausanne500_9f761c';
src: url(/_next/static/media/4e20c6b5c2ff4d71-s.p.woff2) format('woff2');
font-display: swap;
font-style: normal;
}@font-face {font-family: '__TWKLausanne500_Fallback_9f761c';src: local("Arial");ascent-override: 87.94%;descent-override: 19.32%;line-gap-override: 0.00%;size-adjust: 103.61%
}.__className_9f761c {font-family: '__TWKLausanne500_9f761c', '__TWKLausanne500_Fallback_9f761c';font-style: normal
}

@font-face {
font-family: '__TWKLausanne500Italic_dc6303';
src: url(/_next/static/media/4e20c6b5c2ff4d71-s.p.woff2) format('woff2');
font-display: swap;
font-style: italic;
}@font-face {font-family: '__TWKLausanne500Italic_Fallback_dc6303';src: local("Arial");ascent-override: 87.94%;descent-override: 19.32%;line-gap-override: 0.00%;size-adjust: 103.61%
}.__className_dc6303 {font-family: '__TWKLausanne500Italic_dc6303', '__TWKLausanne500Italic_Fallback_dc6303';font-style: italic
}

@font-face {
font-family: '__TWKLausanne600_70ad53';
src: url(/_next/static/media/e663c8077c28d5ba-s.p.woff2) format('woff2');
font-display: swap;
font-style: normal;
}@font-face {font-family: '__TWKLausanne600_Fallback_70ad53';src: local("Arial");ascent-override: 86.68%;descent-override: 19.05%;line-gap-override: 0.00%;size-adjust: 105.11%
}.__className_70ad53 {font-family: '__TWKLausanne600_70ad53', '__TWKLausanne600_Fallback_70ad53';font-style: normal
}

@font-face {
font-family: '__TWKLausanne600Italic_08dba7';
src: url(/_next/static/media/e663c8077c28d5ba-s.p.woff2) format('woff2');
font-display: swap;
font-style: italic;
}@font-face {font-family: '__TWKLausanne600Italic_Fallback_08dba7';src: local("Arial");ascent-override: 86.68%;descent-override: 19.05%;line-gap-override: 0.00%;size-adjust: 105.11%
}.__className_08dba7 {font-family: '__TWKLausanne600Italic_08dba7', '__TWKLausanne600Italic_Fallback_08dba7';font-style: italic
}

@font-face {
font-family: '__TWKLausanne700_de1028';
src: url(/_next/static/media/a7da9d30c6962750-s.p.woff2) format('woff2');
font-display: swap;
font-style: normal;
}@font-face {font-family: '__TWKLausanne700_Fallback_de1028';src: local("Arial");ascent-override: 85.47%;descent-override: 18.78%;line-gap-override: 0.00%;size-adjust: 106.60%
}.__className_de1028 {font-family: '__TWKLausanne700_de1028', '__TWKLausanne700_Fallback_de1028';font-style: normal
}

@font-face {
font-family: '__TWKLausanne700Italic_9025b0';
src: url(/_next/static/media/a7da9d30c6962750-s.p.woff2) format('woff2');
font-display: swap;
font-style: italic;
}@font-face {font-family: '__TWKLausanne700Italic_Fallback_9025b0';src: local("Arial");ascent-override: 85.47%;descent-override: 18.78%;line-gap-override: 0.00%;size-adjust: 106.60%
}.__className_9025b0 {font-family: '__TWKLausanne700Italic_9025b0', '__TWKLausanne700Italic_Fallback_9025b0';font-style: italic
}

